import { logo } from "../../assets"
import { Button } from "../../components/Button"
import { contactRef } from "../../refs"
import styles from "./AppBar.module.scss"

export function AppBar(props:any) {
    return (
        <header className={styles.appbar}>
            <div className={styles.container}>
                <img className={styles.logo} src={logo} alt='Logo' />
                <Button text="Contact Us" onClick={() => contactRef.current?.scrollIntoView({behavior: "smooth"})} />
            </div>
        </header>
    )
}