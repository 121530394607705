import { intro_img } from "../../assets"
import styles from "./Intro.module.scss"


export function Intro(props: any) {
    return (
        <section className={styles.intro}>
            <div className={styles.header}>
                <p>We use an <b>equity-centered</b> approach by prioritizing the
                    needs and policy solutions of low-income families and
                    children and families of color who have historically lived
                    in under-resourced, marginalized communities.
                </p>
            </div>
            <img src={intro_img} alt='' />
        </section>
    )
}