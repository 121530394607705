export const desktopScreen = require('./images/desktop.png');
export const mobileScreen = require('./images/mobile.png');
export const logo = require('./images/logo.png');
export const logo_white = require('./images/logo_white.png');
export const intro_img = require('./images/intro_img.png');
export const ngozi_img = require('./images/ngozi.png');
export const partner_img = require('./images/partners.png');
export const asu_img = require('./images/asu.png');
export const buiid_img = require('./images/buiid.png');
export const cscce_img = require('./images/cscce.png');
export const ida_img = require('./images/ida.png');
export const mecklenburg_img = require('./images/mecklenburg.png');
export const start_early_img = require('./images/start_early.png');
export const linkedin_ico = require('./images/linkedin.png');