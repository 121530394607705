import { linkedin_ico } from "../../assets"
import styles from "./SocialConnect.module.scss"


interface SocialConnectInterface {
    image: string,
    name: string,
    role: string,
    company: string,
    url: string
}

export function LinkedInConnect(props: SocialConnectInterface) {
    return (
        <div onClick={() => window.location.href = props.url} className={styles.social_connect}>
            <div className={styles.image}><img src={props.image} alt='' /></div>
            <div className={styles.social_information}>
                <div>
                    <h1>{props.name}</h1>
                    <p>{props.role},</p>
                    <p>{props.company}</p>
                </div>
                <img src={linkedin_ico} className={styles.icon} alt='' />
            </div>
        </div>
    )
}