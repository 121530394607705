import styles from "./Button.module.scss"

interface ButtonInterface {
    onClick?: () => any,
    text: string
}


export function Button(props: ButtonInterface) {
    return (
        <button className={styles.button} onClick={props.onClick}>{props.text}</button>
    )
}