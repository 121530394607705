import { logo_white } from "../../assets"
import { contactRef } from "../../refs"
import styles from "./Footer.module.scss"


export function Footer(props:any) {
    return (
        <section className={styles.footer}>
            <div className={styles.container}>
                <div ref={contactRef} className={styles.contacts}>
                    <h1>Early Actions LLC</h1>
                    <a href="/">www.earlyactions.com</a>
                    <a href="mailto:info@earlyactions.com">info@earlyactions.com</a>
                    <a href="tel:+12023516810">202-351-6810</a>
                </div>
                <img className={styles.logo} src={logo_white} alt='' />
            </div>
        </section>
    )
}