import { ngozi_img } from "../../assets"
import { LinkedInConnect } from "../../components/SocialConnect"
import styles from "./About.module.scss"


export function About(props: any) {
    return (
        <section className={styles.about}>
            <h1>About Early Actions</h1>
            <p>Early Actions is a consulting group based in Washington, DC, helping clients advance equitable, actionable policy solutions that support the urgent needs of children, families, and communities.</p>
            <LinkedInConnect
                image={ngozi_img}
                name="Ngozi Lawal"
                role="Principal Owner"
                company="Early Actions LLC"
                url="https://www.linkedin.com/in/ngozi-lawal/"
            />
        </section>
    )
}