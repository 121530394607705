import { About } from "./features/About";
import { AppBar } from "./features/AppBar";
import { Clients } from "./features/Client";
import { Footer } from "./features/Footer";
import { Intro } from "./features/Intro";
import { Partner } from "./features/Partner";
import { WWD } from "./features/WWD/index.";

function App() {
  
  return (
    <main className="App">
      <AppBar />
      <Intro />
      <About />
      <WWD />
      <Partner />
      <Clients />
      <Footer />
    </main>
  );
}

export default App;
