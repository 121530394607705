import styles from "./WWD.module.scss"


export function WWD(props: any) {
    return (
        <section className={styles.wwd}>
            <h1>What we do</h1>
            <div className={styles.container}>
                <div className={styles.item}>
                    <p>Facilitate stakeholder discussions</p>
                </div>

                <div className={styles.item}>
                    <p>Thought partnership</p>
                </div>

                <div className={styles.item}>
                    <p>Policy Research & Analysis</p>
                </div>
            </div>
        </section>
    )
}