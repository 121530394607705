import { partner_img } from "../../assets"
import styles from "./Partner.module.scss"


export function Partner(props:any) {
    return (
        <section className={styles.partner}>
            <div className={styles.content}>
                <h1>Who we work with</h1>
                <p>We support local, state and national organizations, working to ensure every child in America reaches their full potential in school and life. </p>
            </div>
            <div className={styles.image}>
                <img src={partner_img} alt='' />
            </div>
        </section>
    )
}