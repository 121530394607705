import { asu_img, buiid_img, cscce_img, ida_img, mecklenburg_img, start_early_img } from "../../assets"
import styles from "./Client.module.scss"


export function Clients(props:any) {
    return (
        <section className={styles.clients}>
            <h1>Our Clients</h1>
            <div className={styles.row}>
                <img src={start_early_img} alt='Start Early' />
                <img src={cscce_img} alt='CSCCE' />
                <img src={asu_img} alt='ASU' />
                <img src={mecklenburg_img} alt='Mecklenburg' />
                <img src={buiid_img} alt='BUIID' />
                <img src={ida_img} alt='IDA' />
            </div>
        </section>
    )
}